import cn from 'classnames';

import css from './smile-image.css';
import type { SmileName, SmilePackName } from './smiles';
import { SvgSmile } from './svg-smile';

export const SmileImage: FC<{
    pack: SmilePackName;
    name: SmileName;
    size?: number;
    onClick?: () => void;
}> = ({ name, onClick, pack, size = 1 }) => (
    <div
        className={cn(css.smile, {
            [css.small]: size === 1,
            [css.large]: size === 2
        })}
        onClick={onClick}
    >
        <SvgSmile name={name} pack={pack} />
    </div>
);
