import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import { SurveySection } from './container';
import css from './survey.css';
import { TextSection } from './text-section';

export const WelcomeText: FC<{
    survey: ISurveyStore;
    marginStyles: CSSProperties;
}> = observer(
    ({
        marginStyles,
        survey: {
            info: {
                params: {
                    main: { welcomePageText }
                }
            },
            isStartPage,
            showWelcomeText
        }
    }) => {
        const { itemsGap, textStyle } = useTextStyle();

        const startSectionStyles = useMemo(
            () => ({
                ...textStyle,
                paddingLeft: marginStyles.paddingLeft,
                paddingRight: marginStyles.paddingRight,
                paddingBottom: itemsGap
            }),
            [itemsGap, marginStyles, textStyle]
        );

        return (
            <>
                {showWelcomeText && (
                    <SurveySection className={cn(CssPageBlock.WELCOME, css.container)} style={startSectionStyles}>
                        <div className={css.welcomeText} {...setHtml(welcomePageText)} />
                    </SurveySection>
                )}
                {isStartPage && (
                    <TextSection
                        className={cn(CssPageBlock.START, css.startText)}
                        style={startSectionStyles}
                        text={welcomePageText}
                    />
                )}
            </>
        );
    }
);
WelcomeText.displayName = 'WelcomeText';
