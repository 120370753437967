import { calcNpsGradient, colorToRgba } from '@webapp/common/lib/color';
import { useMemo } from 'react';
import cn from 'classnames';

import css from './numbers-with-borders.css';

export const NumbersWithBorders: FC<{
    colors: Array<string>;
    itemsCount?: number;
    startsWith?: number;
    onClick?: (value: number) => void;
    selectedValue?: number;
    disabled?: boolean;
}> = ({ colors, disabled, itemsCount = 11, onClick, selectedValue, startsWith = 0 }) => {
    const gradient = useMemo(() => calcNpsGradient(colors, itemsCount), [colors, itemsCount]);

    return (
        <div className={cn(css.numbers, disabled && css.disabled)}>
            {gradient.map((color, index) => {
                const currentLabel = startsWith + index;
                const rgba = colorToRgba(color);
                return (
                    <div
                        style={{
                            color: color,
                            borderColor: color,
                            backgroundColor: rgba ? `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.2)` : undefined
                        }}
                        className={cn(
                            css.number,
                            selectedValue?.toString() === currentLabel.toString() && css.selectedNumber
                        )}
                        onClick={onClick ? () => onClick(currentLabel) : undefined}
                    >
                        {currentLabel}
                    </div>
                );
            })}
        </div>
    );
};
