import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';

import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { StyledDropdown } from '@webapp/ui/lib/styled-dropdown';
import { CssPageBlock, CssQuestionType } from '@webapp/ui/lib/survey-custom';

import css from './nested-list.css';

export const NestedList = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            id,
            items,
            survey: { getNested }
        }
    }) => {
        const { answerStyle, itemsGap, listStyle } = useTextStyle();
        const levelsCount = answers.length;

        const rowStyle = useMemo(
            () => ({
                ...answerStyle,
                rowGap: itemsGap
            }),
            [answerStyle, itemsGap]
        );

        const handleChange = useCallback(
            ({ response: { change, changeExtra } }: ISurveyQuestionAnswerModel, level) =>
                (itemId: number | null) => {
                    const name =
                        itemId === null
                            ? null
                            : items.has(level)
                            ? items.get(level).find(({ id }) => id === itemId).name
                            : null;
                    change(itemId);
                    changeExtra(name);
                    if (level > levelsCount) return;
                    getNested(id, level + 1, itemId);
                },
            [getNested, id, items, levelsCount]
        );

        const createOptions = useCallback(
            (level) => {
                const opts = items.has(level) ? items.get(level) : [];
                return opts.map(({ id, name }) => ({
                    label: name,
                    value: id
                }));
            },
            [items]
        );

        useEffect(() => {
            getNested(id, 1, null);

            if (answers[1]?.response?.value) {
                getNested(id, 2, answers[0]?.response.value);
            }

            if (answers[2]?.response?.value) {
                getNested(id, 3, answers[1]?.response.value);
            }
        }, [getNested, id]);

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.RESIDENCE, css.list)} style={listStyle}>
                {answers.map((answer, idx) => {
                    const options = createOptions(idx + 1);
                    const empty = options.length === 0;
                    return (
                        <div
                            className={cn(css.line, empty && css.hidden, CssPageBlock.ANSWER)}
                            key={idx}
                            style={rowStyle}
                        >
                            <StyledDropdown
                                searchable
                                className={css.field}
                                controlClassName={css.control}
                                options={options}
                                placeholder={answer.value[0] as string}
                                value={answer?.response.value as number}
                                onChange={handleChange(answer, idx + 1)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
);
NestedList.displayName = 'NestedList';
