import cn from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactSlider from 'react-slider';
import { debounce } from 'throttle-debounce';

import { INPUT_DEBOUNCE_DELAY } from '@webapp/common/lib/const';
import { nop } from '@webapp/common/lib/utils';

import { CustomStylesCtx } from '../custom-styles';
import { Textfield, TextfieldType } from '../textfield';

import css from './slider.css';

export const Slider: FC<{
    className?: string;
    fieldClassName?: string;
    controlClassName?: string;
    rangeClassName?: string;
    disabled?: boolean;
    value?: number | null;
    defaultValue?: number;
    min?: number;
    max?: number;
    limit?: number;
    withoutControl?: boolean;
    onChange?: (value: number) => void;
    trackClassName?: string;
    trackBgImage?: string;
    handlerColor?: string;
    labelsBottom?: boolean;
}> = ({
    limit,
    min = 0,
    max = 100,
    defaultValue,
    onChange = nop,
    className,
    controlClassName,
    rangeClassName,
    disabled,
    fieldClassName,
    trackClassName,
    value: propValue,
    withoutControl,
    trackBgImage,
    handlerColor,
    labelsBottom
}) => {
    const rangeRef = useRef<HTMLDivElement>(null);
    const [rangeWidth, setRangeWidth] = useState(0);
    const [innerValue, setInnerValue] = useState(min);
    const [innerText, setTextValue] = useState<string>(String(min));
    const {
        checkbox: {
            checked: { backgroundColor }
        },
        question: { text }
    } = useContext(CustomStylesCtx);

    const rootStyles: CSSProperties = useMemo(
        () => ({
            ['--v-bg-color' as any]: backgroundColor,
            ['--range-width' as any]: `${rangeWidth}px`,
            ['--track-bg-image' as any]: trackBgImage,
            ['--handler-border-color' as any]: handlerColor || undefined
        }),
        [backgroundColor, rangeWidth, trackBgImage, handlerColor]
    );

    const minStyle: CSSProperties = useMemo(
        () => ({
            opacity: innerValue > min * 1.05 ? 1 : 0,
            color: backgroundColor
        }),
        [backgroundColor, innerValue, min]
    );

    const maxStyle: CSSProperties = useMemo(
        () => ({
            opacity: innerValue < max * 0.95 ? 1 : 0,
            color: backgroundColor
        }),
        [backgroundColor, innerValue, max]
    );

    const handleChangeRange = useCallback(
        (value: number): number => {
            let newValue = value;

            if (typeof limit !== 'undefined') {
                newValue = Math.min(newValue, limit);
            }

            setInnerValue(newValue);
            setTextValue(String(newValue));

            return newValue;
        },
        [limit]
    );

    const handleChangeEndDebounced = useMemo(() => debounce(INPUT_DEBOUNCE_DELAY, onChange), [onChange]);

    const handleChangeEnd = useCallback(
        (value: number): void => {
            const val = handleChangeRange(value);
            handleChangeEndDebounced(val);
        },
        [handleChangeEndDebounced, handleChangeRange]
    );

    const handleChangeValue = (value: string): void => {
        if (value === '') {
            setTextValue('');
            handleChangeEnd(0);
        } else {
            let numValue = Math.max(Math.min(parseInt(value || '0'), max), min);
            if (typeof limit !== 'undefined') {
                numValue = Math.min(numValue, limit);
            }
            handleChangeEnd(numValue);
            setTextValue(String(numValue));
        }
    };

    const renderThumb = useCallback(
        (props, state) => {
            return (
                <div {...props} className={css.handler}>
                    <div className={css.label} style={{ color: backgroundColor }}>
                        {propValue || state.valueNow || 0}
                    </div>
                </div>
            );
        },
        [backgroundColor, propValue]
    );

    const renderTrack = useCallback((props, _state) => <div {...props} style={{ ...props.style }} />, []);

    useEffect(() => {
        if (defaultValue !== undefined && defaultValue !== null) {
            setInnerValue(defaultValue);
            setTextValue(String(defaultValue));
        }
    }, [defaultValue]);

    useEffect(() => {
        if (propValue !== undefined && propValue !== null) {
            setInnerValue(propValue);
            setTextValue(String(propValue));
        }
        if (propValue === null) {
            setInnerValue(min);
            setTextValue(String(min));
        }
    }, [propValue]);

    useEffect(() => {
        const rangeHtml = rangeRef.current;
        if (rangeHtml) {
            setRangeWidth(rangeHtml.clientWidth);
            const handleResize = () => setRangeWidth(rangeHtml.clientWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);
    const items = useMemo(() => new Array(max - min + 1).fill(null).map((_, idx) => min + idx), [max, min]);
    return (
        <div className={cn(css.slider, className, labelsBottom && css.sliderLabelsBottom)} style={rootStyles}>
            <div ref={rangeRef} className={cn(css.range, rangeClassName, { [css.disabled]: disabled })}>
                {!labelsBottom && (
                    <div className={css.labels}>
                        <div className={css.label} style={minStyle}>
                            <div className={css.text}>{min}</div>
                        </div>
                        <div className={css.label} style={maxStyle}>
                            <div className={css.text}>{max}</div>
                        </div>
                    </div>
                )}
                <ReactSlider
                    className={css.control}
                    disabled={disabled}
                    max={max}
                    min={min}
                    renderThumb={renderThumb}
                    renderTrack={renderTrack}
                    step={1}
                    trackClassName={cn(css.track, trackClassName)}
                    value={innerValue}
                    onAfterChange={handleChangeEnd}
                    onChange={handleChangeRange}
                />
                {labelsBottom && (
                    <div className={css.labelsNew}>
                        {items.map((item) => (
                            <div
                                className={cn(
                                    (typeof propValue === 'number' || innerValue !== min) &&
                                        item === innerValue &&
                                        css.labelsNewSelected
                                )}
                                style={text}
                                key={item}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {!withoutControl && (
                <Textfield
                    className={cn(css.control, controlClassName)}
                    disabled={disabled}
                    max={max}
                    min={min}
                    rootClassName={cn(css.value, fieldClassName)}
                    type={TextfieldType.NUMBER}
                    value={innerText}
                    onChange={handleChangeValue}
                />
            )}
        </div>
    );
};

Slider.displayName = 'Slider';
