import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import { useContext, useMemo } from 'react';
import tinycolor from 'tinycolor2';

import { setHtml } from '@webapp/common/lib/ui';
import { PointsResults } from '@webapp/ui/lib/point-results/points-results';
import { TimeResults } from '@webapp/ui/lib/time-results/time-results';

import css from '../test-results.css';
import { CompleteText } from './complete-text';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';

const Header: FC<{
    background: string;
}> = ({ background }) => {
    const style = useMemo(() => {
        const c = tinycolor(background);

        c.setAlpha(Math.max(0, c.getAlpha() - 0.45));

        return {
            background: `linear-gradient(to right, ${background}, ${c.toRgbString()})`
        };
    }, [background]);

    return <div className={css.testResults__header} style={style} />;
};

const Description: FC<{
    passed: boolean;
    testPassed: string;
    testNotPassed: string;
}> = ({ passed, testNotPassed, testPassed }) => (
    <div {...setHtml(passed ? testPassed : testNotPassed)} className={css.testResults__bodyDescription} />
);

const Title: FC<{
    percent: number;
    html: any;
}> = ({ html, percent }) => (
    <div className={css.testResults__bodyTitle}>
        <span {...setHtml(html + '&nbsp;')} />
        {percent}%
    </div>
);

const Body: FC<{
    maxScore: number;
    pointsLabel: string;
    fact: number;
    timer: boolean;
    factTime: Duration;
    timerHours: number;
    timerMinutes: number;
    timerSeconds: number;
}> = ({ fact, factTime, maxScore, pointsLabel, timer, timerHours, timerMinutes, timerSeconds }) => (
    <div className={css.testResults__bodyResults}>
        {typeof maxScore === 'number' && isFinite(maxScore) && (
            <PointsResults fact={fact} max={maxScore} pointsLabel='' />
        )}
        {timer && (
            <TimeResults
                factTime={factTime}
                targetTime={dayjs.duration(3600 * timerHours + 60 * timerMinutes + timerSeconds, 'seconds')}
            />
        )}
    </div>
);

export const Results: FC<{
    buttonStyles: { root?: CSSProperties };
    passScore: number;
    params: any;
    percent: number;
    passed: boolean;
    maxScore: number;
    timeAgo: Duration;
    fact: number;
    completeText?: string;
}> = ({
    buttonStyles: {
        root: { backgroundColor }
    },
    fact,
    maxScore,
    params: {
        main: { timer, timerHours, timerMinutes, timerSeconds },
        tests: { passingScoreLabel, scoreLabel, testNotPassed, testPassed, testResultLabel }
    },
    passed,
    passScore,
    percent,
    timeAgo,
    completeText
}) => {
    const {
        question: { text: questionTextStyles }
    } = useContext(CustomStylesCtx);
    return (
        <div className={css.testResults}>
            <Header background={backgroundColor} />
            <div className={css.testResults__body}>
                {isFinite(percent) && <Title html={testResultLabel} percent={percent} />}
                {passed !== null && (
                    <Description passed={passed} testNotPassed={testNotPassed} testPassed={testPassed} />
                )}
                <Body
                    fact={fact}
                    factTime={timeAgo}
                    maxScore={maxScore}
                    pointsLabel={scoreLabel}
                    timer={timer}
                    timerHours={timerHours}
                    timerMinutes={timerMinutes}
                    timerSeconds={timerSeconds}
                />
                {completeText ? <CompleteText style={questionTextStyles} completePageText={completeText} /> : null}
            </div>
        </div>
    );
};
